import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box } from "../../components/StyledComponent";
import { RoutePattern } from "../../routes/RoutePattern";
import { RootState } from "../../Store";
import { MyAssets } from "../../assets";

export default function Landing() {
  const { currentRoute } = useSelector((state: RootState) => state.Routes);

  return (
    <Box className="text-white w-full flex flex-col justify-center items-center bg-black">
      <div className="h-screen overflow-hidden relative w-full">
        {/* <BackgroundVideo /> */}
        {currentRoute !== "/home" ? (
          <Link to={RoutePattern.HOME}>
            <img
              src={MyAssets.LOGO_GIF}
              alt=""
              className="w-full md:w-3/4 h-full mx-auto object-cover"
            />
          </Link>
        ) : (
          <img
            src={MyAssets.LOGO_GIF}
            alt=""
            className="w-full md:w-3/4 h-full mx-auto object-cover"
          />
        )}
      </div>
    </Box>
  );
}
