import { data } from "./data";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { useState } from "react";
import useGlobalService from "../../app/Hooks/useGlobalService";
import { Link } from "react-router-dom";
import { RoutePattern } from "../../routes/RoutePattern";
import { MyAssets } from "../../assets";

const Container = styled.div`
  z-index: 20;
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { setIsScrollableVideoEnded } = useGlobalService();

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute && window.location.pathname === "/home" ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        onClick={() => {
          setIsActive(false);
          setIsScrollableVideoEnded(true);
        }}
        className="mx-2 md:mx-5 text-shadow cursor-pointer text-shadow text-2xl"
      >
        {item.name}
      </ScrollLink>
    ) : !item.isRoute && !item.isAnchor ? (
      <Link
        to={item.to}
        onClick={() => {
          setIsActive(false);
          setIsScrollableVideoEnded(true);
        }}
        className="mx-2 md:mx-5 text-shadow cursor-pointer text-shadow text-2xl"
      >
        {item.name}
      </Link>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        onClick={() => setIsActive(false)}
        className="mx-2 md:mx-5 text-shadow cursor-pointer text-shadow text-2xl"
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container className="mx-auto flex flex-col justify-center items-center font-bold text-white bg-none bg-opacity-60 px-5 absolute top-0 left-0 w-full py-4 lg:px-10">
      <div className="w-full flex justify-end items-center">
        <ScrollLink
          to="home" // id of the section
          smooth
          duration={1000}
          spy={true}
          className="text-shadow cursor-pointer font-extrabold text-shadow text-2xl flex flex-row justify-center items-center mr-auto"
        >
          <img
            src={MyAssets.LOGO_IMG}
            alt="logo"
            className="w-10 md:w-16 mr-2"
          />
          {/* <span>LA'NADO</span> */}
        </ScrollLink>

        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row hidden md:flex">
          {menuItems}
        </div>

        {/* tablet navigation */}
        <div
          className={`uppercase justify-center items-center flex-col flex fixed top-0 bg-opacity-80 transition-all duration-700 w-full h-screen bg-black md:hidden
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          {menuItems}
        </div>
        {/* burger */}
        <div
          className="flex flex-col z-50 cursor-pointer mr-5 md:hidden"
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? "rotate-45" : "rotate-0"
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm mt-1 ${
              isActive ? "hidden" : "block"
            }`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-white rounded-sm ${
              isActive ? "-rotate-45 absolute" : "rotate-0 mt-1"
            } transition-all`}
          />
        </div>
      </div>
    </Container>
  );
}
